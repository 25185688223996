<template>
    <page-loader v-if="meta.actions.onChatLoad.loading === true"></page-loader>
    <div v-else>
        <div class="sp-pc-nochat-actions pt-4" v-if="chat.thread == null" >
            <b-button type="is-primary" class="is-primary-addon-1" :loading="meta.actions.onChatStart.loading" v-on:click.prevent="startChat">Chat with Seller</b-button>
        </div>
        <div v-else>
            <div class="has-background-white p-5 chat-messages-container" ref="chatMessagesContainer">
                <perfect-scrollbar :options="{suppressScrollX: true}">
                    <div v-if="chat.messages.data.length > 0">
                        <div v-for="(message,index) in chat.messages.data.slice().reverse()" v-bind:key="index">
                            <div v-if="message.user.type=='CUSTOMER'">
                                <div class="columns">
                                    <div class="column is-10 is-offset-2">
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFE5C6;" v-if="message.type ==1">
                                            <p> {{ message.body }}</p>
                                        </div>
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFE5C6;" v-else>
                                            <figure class="image is-96x96">
                                                <img :src="imageUrl(message.body)">
                                            </figure>
                                        </div>
                                        <div class="cmc_user-footer has-text-right">
                                            <i class="fas fa-user"></i> Me
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="message.user.type=='SELLER'">
                                <div class="columns">
                                    <div class="column is-10">
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFD8D8;" v-if="message.type ==1">
                                            <p> {{ message.body }}</p>
                                        </div>
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFD8D8;" v-else>
                                            <figure class="image is-96x96">
                                                <img :src="imageUrl(message.body)">
                                            </figure>
                                        </div>
                                        <div class="cmc_user-footer">
                                            <i class="fas fa-user"></i> Seller
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="" style="position: absolute; width:320px; bottom: 0px;">
                <form action="" v-on:submit.prevent="onChatSubmit">
                    <b-field>
                        <b-input placeholder="Type your replies here"
                                 type="search"
                                 custom-class="chat-input-box"
                                 expanded
                                 v-model="chatForm.data.body"
                        >
                        </b-input>
                        <p class="control">
                            <b-button @click="openImageuploadPopupModal"
                                      class="button chat-input-button"
                                      icon-pack="fas"
                                      icon-right="camera"
                                      size="is-medium"
                            >
                            </b-button>
                            <b-button native-type="submit"
                                      class="button chat-input-button"
                                      icon-pack="fas"
                                      icon-right="paper-plane"
                                      size="is-medium"
                                      :loading="chatForm.loading"
                                      :disabled="chatForm.loading"
                            >
                            </b-button>
                        </p>
                    </b-field>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, watch } from 'vue'
import { EnquiryChatService } from '@/services/enquiry-chat.service'
import Echo from 'laravel-echo'
import { API_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '@/.env'
import axios from 'axios'
import ImageUploadPopupModal from '@/components/search/ImageUploadPopupModal'

export default {
    name: 'ProductChat',
    props: ['sellerResponse', 'term'],
    setup(props) {
        const meta = reactive({
            actions: {
                onChatLoad: {
                    loading: true
                },
                onChatStart: {
                    loading: false
                }
            }
        })

        const chat = reactive({
            thread: null,
            messages: {
                data: []
            },
            pusher: null,
            echo: null
        })

        const chatForm = reactive({
            data: {
                thread_id: null,
                body: ''
            },
            loading: false
        })

        const init = async () => {
            meta.actions.onChatLoad.loading = true
            chat.thread = await EnquiryChatService.getActiveThread({ response_id: props.sellerResponse.id })
            chat.messages = await EnquiryChatService.getThreadMessages(chat.thread.id)
            chatForm.data.thread_id = chat.thread.id
            // echo init
            // setup pusher and echo.
            chat.pusher = require('pusher-js')
            chat.echo = new Echo({
                broadcaster: 'pusher',
                key: PUSHER_APP_KEY,
                cluster: PUSHER_APP_CLUSTER,
                forceTLS: true,
                authEndpoint: API_URL + '/api/customer/auth/ws',
                authorizer: (channel) => ({
                    authorize: (socketId, callback) => {
                        axios.post(API_URL + '/api/customer/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                            .then(response => {
                                callback(null, response.data)
                            })
                            .catch(error => {
                                callback(error)
                            })
                    }
                })
            })

            chat.echo.private(`f.enquiry-chat.${chat.thread.id}`)
                .listen('EnquiryChatNewMessage', (e) => {
                    if (e.message.user.type === 'SELLER') {
                        chat.messages.data.unshift(e.message)
                    }
                })

            meta.actions.onChatLoad.loading = false
        }

        const onChatSubmit = async () => {
            var formdata = chatForm.data
            if (!formdata.body.length > 0) {
                return
            }
            try {
                chatForm.loading = true
                const message = await EnquiryChatService.postNewMessage(chatForm.data)
                chat.messages.data.unshift(message)
                chatForm.data.body = ''
            } finally {
                chatForm.loading = false
            }
        }

        const startChat = async () => {
            meta.actions.onChatStart.loading = true
            await init()
            meta.actions.onChatStart.loading = false
        }

        const openImageuploadPopupModal = () => {
            props.$buefy.modal.open({
                component: ImageUploadPopupModal,
                parent: props,
                width: 500,
                canCancel: true,
                trapFocus: true,
                props: chatForm.data,
                events: {
                    close: async (guid) => {
                        const data = {
                            thread_id: chatForm.data.thread_id,
                            body: guid,
                            type: 2
                        }
                        try {
                            const message = await EnquiryChatService.postNewMessage(data)
                            chat.messages.data.unshift(message)
                        } catch (e) {
                            if (process.env.NODE_ENV === 'development') {
                                // eslint-disable-next-line no-console
                                console.log(e)
                            }
                        }
                    }
                }
            })
        }

        watch(() => props.sellerResponse, async (newSellerResponse, oldSellerResponse) => {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('SellerResponse modified to', newSellerResponse)
            }
            if (oldSellerResponse.id === newSellerResponse.id) {
                return
            }
            try {
                chat.echo.disconnect()
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.error(e)
                }
            }

            if (newSellerResponse.thread_id !== null) {
                await init()
            }
        })

        onMounted(async () => {
            chatForm.data.thread_id = props.sellerResponse.thread_id

            if (props.sellerResponse.thread_id !== null) {
                await init()
            }
        })

        return {
            meta,
            chat,
            chatForm,
            init,
            onChatSubmit,
            startChat,
            openImageuploadPopupModal
        }
    }
}
</script>

<style scoped>
.ps {
    height: 544px;
}
</style>
