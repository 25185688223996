import { createRouter, createWebHistory } from 'vue-router';
import { checkAccessMiddleware, setPageTitleMiddleware } from './middlewares';
import { routes } from './routes';

// Create the router instance
const router = createRouter({
    history: createWebHistory(), // Replaces mode: 'history'
    routes, // Your routes array
    linkActiveClass: 'is-active', // Class for active links
});

// Apply middleware
// router.beforeEach(initCurrentUserStateMiddleware); // If you need another middleware, uncomment and use it
router.beforeEach(checkAccessMiddleware);
router.beforeEach(setPageTitleMiddleware);

export default router;
