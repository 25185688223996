<template>
    <div id="home-app" class="">
        <section class="hero is-primary-addon-1 is-fullheight">
            <!-- Hero head: will stick at the top -->
            <div class="hero-head fh-hero-head">
                <nav class="navbar fh-navbar">
                    <div class="container">
                        <div class="navbar-brand">
                            <div class="p-4">
                                <img style="width: 10rem;" src="/images/new_logo_name_white.png">
                            </div>
                            <span class="home-navbar-burger" v-if="$store.getters['auth/isAuthenticated']"
                                data-target="navbarMenuHeroA" @click="open = true">
                                <!-- <span></span>
                            <span></span>
                            <span></span> -->
                                <b-button type="has-text-white pt-5 is-hidden-desktop"
                                    style="background-color: transparent; border-color: transparent;"
                                    :icon-right="active ? 'menu-up' : 'menu-down'">{{ $store.state.auth.currentUser.name
                                    }}
                                </b-button>
                                <b-button type="has-text-white pt-5 is-hidden-desktop"
                                    style="background-color: transparent; border-color: transparent;"
                                    :icon-right="'menu-up'">{{ $store.state.auth.currentUser.name }}
                                </b-button>
                            </span>
                            <span class="navbar-burger" style="color: white;"
                                v-if="!$store.getters['auth/isAuthenticated']" data-target="navbarMenuHeroA"
                                v-on:click="openLoginPopupModal">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </div>
                        <div id="navbarMenuHeroA" class="navbar-menu">
                            <div class="navbar-end">
                                <div class="navbar-item">
                                    <span v-if="!$store.getters['auth/isAuthenticated']">
                                        <a v-on:click="openLoginPopupModal">
                                            <img class="toggle-button-image-2 mr-4" style="width: 2.5rem"
                                                src="/images/home-login.svg">
                                        </a>
                                    </span>
                                    <span v-if="$store.getters['auth/isAuthenticated']">
                                        <b-dropdown aria-role="list">
                                            <template #trigger="{ active }">
                                                <b-button type="has-text-white"
                                                    style="background-color: transparent; border-color: transparent;"
                                                    :icon-right="active ? 'menu-up' : 'menu-down'">{{
                                                        $store.state.auth.currentUser.name }} </b-button>
                                            </template>
                                            <template>
                                                <b-button type="has-text-white"
                                                    style="background-color: transparent; border-color: transparent;"
                                                    :icon-right="'menu-up'">{{ $store.state.auth.currentUser.name }}
                                                </b-button>
                                            </template>
                                            <b-dropdown-item v-on:click="onLogout"
                                                aria-role="listitem">Logout</b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </div>
                                <div class="navbar-item">
                                    <div>
                                        <div v-if="$store.getters['auth/isAuthenticated']" style="width: 1.5rem"
                                            class="is-clickable" @click="open = true">
                                            <img class="toggle-button-image-1" src="/images/menubar-white.svg">
                                        </div>
                                        <b-sidebar type="is-primary-sidebargradient has-text-weight-semibold"
                                            :fullheight="fullheight" :fullwidth="fullwidth" :overlay="overlay"
                                            :right="right" v-model="open">
                                            <div class="px-4 pt-6">
                                                <b-menu>
                                                    <b-menu-list label="Menu">
                                                        <b-menu-item tag="router-link" :to="{ name: 'Enquiry' }"
                                                            icon="information-outline"
                                                            label="My Enquiries"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'OrdersList' }"
                                                            icon="cart-outline" label="My Orders"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'Profile' }"
                                                            icon="account-outline" label="My Profile"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'SearchHistory' }"
                                                            icon="history" label="Search History"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'SellerDirectory' }"
                                                            icon="store" label="Seller Directory"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'PrivacyPolicy' }"
                                                            icon="shield" label="Privacy Policy"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'RefundPolicy' }"
                                                            icon="undo" label="Refund Policy"></b-menu-item>
                                                        <b-menu-item tag="router-link"
                                                            :to="{ name: 'TermsAndConditions' }" icon="file"
                                                            label="Terms & Conditions"></b-menu-item>
                                                    </b-menu-list>
                                                    <b-menu-list label="Actions">
                                                        <b-menu-item v-on:click="onLogout" icon="logout"
                                                            label="Logout"></b-menu-item>
                                                    </b-menu-list>
                                                </b-menu>
                                            </div>
                                            <div class="column p-0 " style="display:flex;align-items:flex-end">
                                                <img class="column p-0 is-pulled-left is-three-quarters"
                                                    src="/images/menubar-icon-lady.svg">
                                            </div>

                                        </b-sidebar>
                                    </div>
                                    <div v-if="!$store.getters['auth/isAuthenticated']">
                                        <div class="is-clickable" v-on:click="openLoginPopupModal">
                                            <img class="toggle-button-image-1" style="width: 1.5rem"
                                                src="/images/menubar-white.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>

            <!-- Hero content: will be in the middle -->
            <div class="hero-body py-0 fh-hero-body">
                <div class="container">
                    <div class="columns is-vcentered mt-auto">
                        <div class="column">
                            <h1 class="title is-size-1 has-text-white is-size-3-touch mb-2">
                                What are you searching today?!
                            </h1>
                            <form action="" v-on:submit.prevent="onSearchSubmit" ref="searchFormRef">
                                <div class="field has-addons">
                                    <div class="control is-expanded is-hidden-touch">
                                        <b-field>
                                            <b-autocomplete placeholder="iPhone 12 cover, Samsung note 9" type="search"
                                                expanded v-model="search_form.data.query" size="is-medium"
                                                custom-class="sb-input-search" field="name"
                                                @typing="getSearchSuggestions" :data="search_form.suggestion.data"
                                                :clearable="true" :confirm-keys="search_form.suggestion.confirm_keys"
                                                @keyup="completeSearchSuggestion" ref="searchQueryInput"
                                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
                                            </b-autocomplete>
                                            <div class="control">
                                                <b-button native-type="submit"
                                                    class="is-primary-addon-2 search-button-color"
                                                    :loading="search_form.loader" icon-pack="fas" size="is-medium"
                                                    icon-right="search"
                                                    style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                                </b-button>
                                            </div>
                                        </b-field>
                                    </div>
                                    <div class="control is-expanded is-hidden-desktop">
                                        <b-field>
                                            <b-autocomplete placeholder="iPhone 12 cover , Samsung note 9" type="search"
                                                expanded v-model="search_form.data.query" custom-class="sb-input-search"
                                                field="name" @typing="getSearchSuggestions"
                                                :data="search_form.suggestion.data" :clearable="true"
                                                :confirm-keys="search_form.suggestion.confirm_keys"
                                                @keyup="completeSearchSuggestion" ref="searchQueryInput"
                                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">

                                            </b-autocomplete>
                                            <div class="control">
                                                <b-button native-type="submit"
                                                    class="is-primary-addon-2 search-button-color"
                                                    :loading="search_form.loader" icon-pack="fas" icon-right="search"
                                                    style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                                </b-button>
                                            </div>
                                        </b-field>
                                    </div>
                                </div>
                            </form>
                            <div class="pt-2">
                                <b-dropdown v-model="user.selected_address" aria-role="list" :triggers="['click']"
                                    v-on:change="onSelectSavedAddress" ref="locationDropdown">
                                    <template #trigger>
                                        <b-field>
                                            <div class="control has-icons-left  is-clearfix ">
                                                <input type="text" @keydown.enter.prevent autocomplete="off"
                                                    ref="locationQuery"
                                                    v-bind:placeholder="search_form.data.location.display_name"
                                                    v-model="search_form.data.location.input_value"
                                                    class="input input-naked placeholder-color"
                                                    style="height:2rem; background: transparent; width: 20.5rem;">
                                                <span class="icon is-left" style="height:2rem;">
                                                    <img style="width:1rem" class="toggle-button-image-2"
                                                        src="/images/locationicon.svg">
                                                </span>
                                            </div>
                                        </b-field>
                                    </template>

                                    <b-dropdown-item aria-role="menu-item" :focusable="false" custom
                                        v-if="!$store.getters['auth/isAuthenticated']">
                                        <a v-on:click="openLoginPopupModal">Login </a> to view history
                                    </b-dropdown-item>

                                    <b-dropdown-item v-for="(address, index) in user.saved_addresses" :key="index"
                                        :value="address" aria-role="listitem">
                                        <div class="media">
                                            <b-icon class="media-left" icon="clock-time-three-outline"></b-icon>
                                            <div class="media-content">
                                                <h3>{{ address.nick_name }} - {{ address.address_line_1 }}</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="pt-2">
                                <button class="button is-light is-primary is-small"
                                    v-on:click="openCustomSearchPopupModal">Custom
                                    Search <span class="icon pl-5 pr-5"><i class="fas fa-camera"></i></span></button>
                            </div>
                        </div>
                        <div class="column is-hidden-touch">
                            <b-carousel :indicator-inside="false">
                                <b-carousel-item v-for="(item, i) in 1" :key="i">
                                    <b-image class="image" :src="getImgUrl(i)"></b-image>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                        <div class="column mobile-banner is-hidden-desktop pb-0">
                            <b-carousel :indicator-inside="false">
                                <b-carousel-item v-for="(item, i) in 1" :key="i">
                                    <b-image class="image mobile-banner" :src="getImgUrl(i)"></b-image>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Hero footer: will stick at the bottom -->
            <div class="hero-foot fh-hero-foot">
                <div class="container">
                    <div class="columns is-mobile is-hidden-touch">
                        <div class="column is-narrow">
                            <div class="columns">
                                <div class="column">
                                    <a href="https://apps.apple.com/in/app/findhub/id1579928022">
                                        <img style="width: 164px;" src="/images/appstore.png">
                                    </a>
                                </div>
                                <div class="column">
                                    <a href="https://play.google.com/store/apps/details?id=app.findhub.main">
                                        <img style="width: 164px;" src="/images/playstore.png">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <router-link :to="{ name: 'SupportChat' }">
                                        <button
                                            class="button is-rounded is-medium has-background-dark border-none mr-1">
                                            <i class="fas fa-comments mr-1 is-pulled-left" style="color:white"></i>
                                            <h4 class="has-text-white is-size-6 is-multiline is-size-6-mobile">Chat with
                                                us</h4>
                                        </button>
                                    </router-link>
                                </p>
                                <p class="control">
                                    <button class="button is-rounded is-medium has-background-dark border-none">
                                        <h4 class="has-text-white is-size-6 is-multiline is-size-6-mobile pr-2">Whatsapp
                                        </h4>
                                        <a href="https://wa.me/919061501127">
                                            <img style="width: 27px;" class="pt-1" src="/images/whatsapp_logo.png">
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-mobile is-hidden-desktop" style="padding: 0px 24px 24px 24px;">
                        <div class="column is-narrow py-0">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <a href="https://apps.apple.com/in/app/findhub/id1579928022">
                                        <button class="button is-rounded has-background-dark border-none mr-1 px-4">
                                            <img style="width: 20px;" src="/images/app-store-logo.png">
                                        </button>
                                    </a>
                                </p>
                                <p class="control">
                                    <a href="https://play.google.com/store/apps/details?id=app.findhub.main">
                                        <button class="button is-rounded has-background-dark border-none px-4">
                                            <img style="width: 24px;" src="/images/play-store-logo.png">
                                        </button>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="column py-0">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <router-link :to="{ name: 'SupportChat' }">
                                        <button class="button is-rounded has-background-dark border-none mr-1 px-4">
                                            <i class="fas fa-comments mr-1 is-pulled-left" style="color:white"></i>
                                        </button>
                                    </router-link>
                                </p>
                                <p class="control">
                                    <button class="button is-rounded has-background-dark border-none px-4">
                                        <a href="https://wa.me/919061501127">
                                            <img style="width: 20px;" class="pt-1" src="/images/whatsapp_logo.png">
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="tabs has-background-dark">
                    <div class="container">
                        <footer-home></footer-home>
                    </div>
                </nav>
            </div>
        </section>
    </div>
</template>

<script>
import { ref, onMounted, nextTick, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { SearchService } from '@/services/search.service'
import { AuthService } from '@/services/auth.service'
import LoginPopupModal from '@/components/auth/LoginPopupModal'
import { ProfileService } from '@/services/profile.service'
import { MapUtil } from '@/utils/map.util'
import _ from 'lodash'
import FooterHome from '../layout/FooterHome.vue'
import CustomSearchPopupModal from '@/components/customSearch/CustomSearchPopupModal'

export default {
    components: {
        FooterHome
    },
    name: 'SearchHome',
    setup() {
        const store = useStore()
        const router = useRouter()
        const $buefy = inject('$buefy'); // Inject Buefy

        const search_form = ref({
            loader: false,
            suggestion: {
                loading: false,
                data: [],
                confirm_keys: ['Tab', 'Enter', ' ']
            },
            data: {
                location: {
                    latitude: null,
                    longitude: null,
                    display_name: 'Enter your location',
                    google_place_id: null
                },
                query: '',
                address_id: null
            }
        })

        const user = ref({
            saved_addresses: [],
            selected_address: null
        })

        const searchFormRef = ref(null)
        const locationQuery = ref(null)
        const searchQueryInput = ref(null)
        const locationDropdown = ref(null)

        const open = ref(false)
        const overlay = ref(true)
        const fullheight = ref(true)
        const fullwidth = ref(false)
        const right = ref(true)

        const currentLocation = ref({ latitude: 0, longitude: 0 })
        const isLoading = ref(true)
        const googleAutoComplete = ref(null)

        const getImgUrl = () => {
            return '/images/banner.svg'
        }

        const openLoginPopupModal = () => {
            if ($buefy) {
                $buefy.modal.open({
                    component: LoginPopupModal,
                    parent: null,
                    width: 600,
                    canCancel: true,
                    trapFocus: true,
                    props: {
                        currentLocation: ref({ latitude: 0, longitude: 0 }).value,
                    },
                });
            } else {
                // eslint-disable-next-line 
                console.error('$buefy is not defined'); // Add error handling
            }
        };

        const openCustomSearchPopupModal = () => {
            const isAuthenticated = store.getters['auth/isAuthenticated']
            if (isAuthenticated) {
                store.dispatch('modal/open', {
                    component: CustomSearchPopupModal,
                    parent: this,
                    width: 600,
                    canCancel: true,
                    trapFocus: true,
                    props: search_form.value.data.location
                })
            } else {
                openLoginPopupModal()
            }
        }

        const fetchCurrentLocation = async () => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    var currentLatitude = position.coords.latitude
                    var currentLongitude = position.coords.longitude

                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log(currentLongitude, currentLatitude)
                    }

                    if (window.google && window.google.maps) {
                        const geocoder = new window.google.maps.Geocoder()

                        geocoder
                            .geocode({ location: { lat: currentLatitude, lng: currentLongitude } })
                            .then((response) => {
                                if (response.results[0]) {
                                    const placeInfo = response.results[0]
                                    const placeData = MapUtil.parseAddressComponents(placeInfo.address_components)
                                    updateSelectedLocation(MapUtil.getLocalDisplayName(placeData), placeInfo.geometry.location.lat(), placeInfo.geometry.location.lng())
                                } else {
                                    window.alert('No results found')
                                }
                            })
                            .catch((e) => window.alert('Geocoder failed due to: ' + e))
                    }
                })
            }
        }

        const onLogout = async () => {
            if (store.getters['auth/isAuthenticated']) {
                await AuthService.attemptLogout()
                window.location.reload()
            }
        }

        const getSearchSuggestions = _.debounce(async function (text) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('Inside getSearchSuggestion ' + text.length)
            }

            if (!text.length || text.length < 3) {
                search_form.value.suggestion.data = []
                return
            }
            search_form.value.suggestion.loading = true
            try {
                const res = await SearchService.getSearchSuggestions({ params: { text: text } })
                search_form.value.suggestion.data = []
                const flattenedData = res.flatMap(item => item)

                flattenedData.forEach((item) => {
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log(item)
                    }
                    search_form.value.suggestion.data.push(item)
                })
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.error('Error fetching search suggestions:', e)
                }
            } finally {
                search_form.value.suggestion.loading = false
            }
        }, 500)

        const completeSearchSuggestion = (event) => {
            if (event.key === 'Enter') {
                searchFormRef.value?.requestSubmit()
            }
        }

        const onSearchSubmit = async () => {
            const isAuthenticated = store.getters['auth/isAuthenticated']
            if (isAuthenticated) {
                if (!_.isNumber(search_form.value.data.location.latitude) || !_.isNumber(search_form.value.data.location.longitude)) {
                    locationQuery.value?.focus()
                    await fetchCurrentLocation()
                    return
                }

                if (_.isEmpty(search_form.value.data.query)) {
                    searchQueryInput.value?.focus()
                    return
                }

                search_form.value.loader = true
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log(search_form.value.data.address_id)
                }
                try {
                    const reqData = {
                        term: search_form.value.data.query,
                        latitude: search_form.value.data.location.latitude,
                        longitude: search_form.value.data.location.longitude,
                        google_place_id: search_form.value.data.location.google_place_id,
                        address_id: search_form.value.data.address_id,
                        type: 1
                    }
                    const response = await SearchService.save(reqData)
                    if (response?.data?.id) {
                        router.push('/search-product/' + response.data.id)
                    }
                } catch (e) {
                    search_form.value.loader = false
                }
            } else {
                openLoginPopupModal()
            }
        }

        const onPostLoginSuccessHandler = async () => {
            user.value.saved_addresses = []
            const response = await ProfileService.getAddress()
            user.value.saved_addresses.push(...response.data)
        }

        const onSelectSavedAddress = (value) => {
            updateSelectedLocation(`${value.nick_name} - ${value.address_line_1}`, _.toNumber(value.latitude), _.toNumber(value.longitude))
            search_form.value.data.address_id = value.id
        }

        const updateSelectedLocation = (displayValue, latitude, longitude) => {
            search_form.value.data.location.display_name = displayValue
            search_form.value.data.location.input_value = ''
            search_form.value.data.location.latitude = latitude
            search_form.value.data.location.longitude = longitude
        }

        const loadGoogleMapsScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}`
                script.async = true
                script.defer = true
                script.onload = resolve
                script.onerror = reject
                document.head.appendChild(script)
            })
        }

        onMounted(async () => {
            await loadGoogleMapsScript()
            await nextTick()

            if (window.google && window.google.maps) {
                const autocomplete = new window.google.maps.places.Autocomplete(locationQuery.value)
                autocomplete.setTypes(['(regions)'])

                autocomplete.addListener('place_changed', () => {
                    const placeInfo = autocomplete.getPlace()
                    if (placeInfo && _.has(placeInfo, 'address_components') && placeInfo.address_components.length > 0) {
                        search_form.value.data.location.google_place_id = placeInfo.place_id
                        const placeData = MapUtil.parseAddressComponents(placeInfo.address_components)
                        search_form.value.data.address_id = null
                        user.value.selected_address = null
                        updateSelectedLocation(MapUtil.getLocalDisplayName(placeData), placeInfo.geometry.location.lat(), placeInfo.geometry.location.lng())
                    }
                })
            }

            locationQuery.value?.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    e.preventDefault()
                }
                if (locationDropdown.value?.$el.classList.contains('is-active')) {
                    locationDropdown.value.toggle()
                }
            })

            if (store.getters['auth/isAuthenticated']) {
                await onPostLoginSuccessHandler()
            }

            await fetchCurrentLocation()
        })

        return {
            search_form,
            user,
            open,
            overlay,
            fullheight,
            fullwidth,
            right,
            currentLocation,
            isLoading,
            googleAutoComplete,
            getImgUrl,
            openLoginPopupModal,
            openCustomSearchPopupModal,
            fetchCurrentLocation,
            onLogout,
            getSearchSuggestions,
            completeSearchSuggestion,
            onSearchSubmit,
            onPostLoginSuccessHandler,
            onSelectSavedAddress,
            updateSelectedLocation
        }
    }
}
</script>

<style scoped></style>
