<template>
  <div>
    <SplashScreen v-if="showSplash" @hide-splash="hideSplashScreen" />
    <div v-else>
      <div v-if="showInstallPrompt" class="install-banner">
        <p>{{ installPromptText }}</p>
        <button v-if="showAndroidInstallPrompt" @click="installAndroidApp">Install App</button>
        <button v-if="isAppInstalled" :href="appLink">Open App</button>
      </div>
      <p v-if="isMobileDevice">You are on a mobile device.</p>
      <p v-else>You are on a desktop or laptop.</p>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import SplashScreen from "@/components/SplashScreen.vue";

export default {
  name: "AppLayout",
  components: {
    SplashScreen,
  },
  data() {
    return {
      showSplash: false,
      showInstallPrompt: false,
      showAndroidInstallPrompt: false,
      deferredPrompt: null,
      installPromptText: "",
      isAppInstalled: false,
      isMobileDevice: false,
    };
  },
  methods: {
    hideSplashScreen() {
      this.showSplash = false;
    },
    checkInstallationStatus() {
      this.isAppInstalled =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone === true;

      if (this.isIos() && !this.isAppInstalled) {
        this.showInstallPrompt = true;
        this.installPromptText = "Install this app on your iPhone: tap the share icon and then 'Add to Home Screen'.";
      }
    },
    setupAndroidInstallPrompt() {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.showAndroidInstallPrompt = true;
        this.showInstallPrompt = true;
        this.installPromptText = "Install this app on your Android device";
      });
    },
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            // eslint-disable-next-line no-console
            console.log("User accepted the A2HS prompt");
          } else {
            // eslint-disable-next-line no-console
            console.log("User dismissed the A2HS prompt");
          }
          this.deferredPrompt = null;
          this.showInstallPrompt = false;
        });
      }
    },
    isIos() {
      return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    },
    checkDeviceType() {
      this.isMobileDevice = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/.test(navigator.userAgent);
    },
  },
  mounted() {
    const hasSeenSplashScreen = localStorage.getItem("hasSeenSplashScreen");

    if (!hasSeenSplashScreen) {
      this.showSplash = true;
      setTimeout(() => {
        this.hideSplashScreen();
      }, 10000); // Show splash screen for 10 seconds
    }

    this.checkInstallationStatus();
    this.setupAndroidInstallPrompt();
    this.checkDeviceType();

    window.addEventListener('resize', this.checkDeviceType);
  },
  beforeUnmount() { // Use beforeUnmount instead of beforeDestroy
    window.removeEventListener('resize', this.checkDeviceType);
  },
};
</script>

<style scoped>
.install-banner {
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
}
</style>
