<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onLoad.loading === true"></page-loader>
        <div v-else>
            <div v-if="order.status!==3">
                There has been an error while confirming your order. Please check orders page after a while.
            </div>
            <div v-else-if="order.status===3" class="columns is-mobile is-centered">
                <div class="column order-confirmantion">
                    <img data-v-30de49ae="" height="356" width="854" src="/images/order-confirm.png">
                    <h3 class="title has-text-centered has-text-weight-semibold">We've received your order!</h3>
                    <p class="subtitle has-text-centered">
                        Thank you for shopping with us.
                        <br>Your Order ID is {{ order.order_number }}.
                        <br>Our support team will contact you to verify the order.
                        <br>Once verified we will provide you with payment and delivery options.
                    </p>
                    <div class="has-text-centered">
                        <router-link to="#">
                            <button class="button is-rounded mx-2 has-text-white has-background-grey-darker">Track my Order</button>
                        </router-link>
                        <router-link :to="{ name: 'SearchHome' }">
                            <button class="button is-rounded is-primary is-primary-addon-1 mx-2"><i class="fa fa-home"><span
                                    class="ml-2"></span></i>Back to Home
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import _ from 'lodash'
import { OrderService } from '@/services/order.service'

const router = useRouter()
const route = useRoute()

const meta = ref({
    actions: {
        onLoad: {
            loading: true
        }
    }
})
const order = ref(null)

onMounted(async () => {
    const order_id = route.query.order_id
    if (!_.isNumber(parseInt(order_id))) {
        router.push({ name: 'Page404' })
        return
    }

    try {
        const confirmedOrder = await OrderService.confirmPayment({ order_id })
        order.value = confirmedOrder
        meta.value.actions.onLoad.loading = false
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('There was an error confirming your payment. Please try again', e)
        }
    }
})
</script>

<style scoped>

</style>
