<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.loadCustomerAddresses.loading === true"></page-loader>
        <div v-else>
            <div class="page-title">
                <div class="title">
                    Select a delivery address
                </div>
                <div class="subtitle">
                    Choose from your saved addresses or create a new address.
                </div>
            </div>
            <div class="page-content">
                <b-loading :is-full-page="true" v-model="meta.actions.onAddressSelect.loading">
                    <page-loader :loading-text="meta.actions.onAddressSelect.loadingText"></page-loader>
                </b-loading>
                <div class="level">
                    <div class="level-left">
                        <b-button
                            @click="onClickAddAddress"
                            label="Add new address"
                            type="is-default"
                            class="is-rounded"
                            icon-left="plus" />
                    </div>
                    <div class="level-right">
                        <b-button
                            :disabled="selectedAddress===null"
                            @click="onProceed"
                            label="Proceed"
                            type="is-primary"
                            class="is-primary-addon-2"
                            icon-right="arrow-right-bold" />
                    </div>
                </div>
                <div class="columns is-multiline oa__saved_address_results_container" >
<!--                    <div class="column is-one-third">-->
<!--                        <div class="oa__saved_address">-->
<!--                            <div class="card">-->
<!--                                <div class="card-content">-->
<!--                                    Add Address-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="column is-one-third" v-for="(address,index) in savedAddressList" :key="index">
                        <div class="tile is-child box address-container" v-bind:class="{'is-active': index===selectedAddress}">
                            <div class="pb-4" style="cursor: pointer" @click="onSelectAddress(index)">
                                <p class="has-text-weight-bold pb-2" style="font-size: 0.875rem;">{{address.name}}</p>
                                <p style="font-size: 0.875rem;">{{address.address_line_1}}</p>
                                <p style="font-size: 0.875rem;">{{address.address_line_2}}</p>
                                <p style="font-size: 0.875rem;">{{address.landmark}}</p>
                                <p style="font-size: 0.875rem;">{{address.city}},{{address.state}},{{address.postcode}}</p>
                                <p style="font-size: 0.875rem;">{{address.country}}</p>
                                <p style="font-size: 0.875rem;">{{address.isd_code}}-{{address.phone_number}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { OrderService } from '@/services/order.service'
import { CartService } from '@/services/cart.service'
import PhoneNumberModal from '@/components/auth/PhoneNumberModal'
import _ from 'lodash'
import AddAddressPopupModal from '@/components/profile/AddAddressPopupModal'

export default {
    name: 'OrderDeliveryAddress',
    data: function () {
        return {
            savedAddressList: [],
            selectedAddress: null,
            cart: null,
            meta: {
                actions: {
                    loadCustomerAddresses: {
                        loading: true
                    },
                    onAddressSelect: {
                        loading: false,
                        loadingText: ''
                    }
                }
            }
        }
    },
    async mounted () {
        await this.getAddress()
        const defaultAddress = await CartService.getDefaultAddress()
        const cartResponse = await CartService.getCart()
        this.meta.actions.loadCustomerAddresses.loading = false
        this.cart = cartResponse
        if (_.isObject(defaultAddress) && defaultAddress.address_id) {
            const index = _.findIndex(this.savedAddressList, (address) => {
                return address.id === defaultAddress.address_id
            })
            this.selectedAddress = index
        }
    },

    methods: {
        async onProceed () {
            if (!this.validatePhoneNumber()) {
                return
            }
            await this.updateAddress(this.savedAddressList[this.selectedAddress])
        },
        onClickAddAddress () {
            this.$buefy.modal.open({
                parent: this,
                component: AddAddressPopupModal,
                trapFocus: true,
                // onCancel () {
                //     // window.location.reload()
                //     console.log('address list should be shown')
                // },
                events: {
                    close: (response) => {
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.log('ON CLOSE')
                        }
                        if (response && response.data) {
                            this.savedAddressList.unshift(response.data)
                            this.selectedAddress = 0
                        }
                    }
                }
            })
        },
        onSelectAddress (index) {
            this.selectedAddress = index
        },
        validatePhoneNumber () {
            const currentUser = this.$store.state.auth.currentUser
            if (currentUser && _.isNull(currentUser.mobile_number)) {
                this.$buefy.modal.open({
                    component: PhoneNumberModal,
                    parent: this,
                    width: 600,
                    canCancel: true,
                    trapFocus: true,
                    onCancel: () => {

                    }
                })
                return false
            }
            return true
        },
        async getAddress () {
            try {
                const response = await OrderService.getAddress()
                this.savedAddressList = response.data
                if (_.isArray(this.savedAddressList) && this.savedAddressList.length > 0) {
                    this.selectedAddress = 0
                }
            } catch (e) {
                this.$log.error('Error while fetching saved addresses', e)
            }
        },
        async updateAddress (address) {
            /**
             * 1. create order with the cart_id.
             * 2. use the order to update the address.
             * 3. navigate to payment page.
             */

            try {
                this.meta.actions.onAddressSelect.loading = true
                this.meta.actions.onAddressSelect.loadingText = 'Creating order...'
                await OrderService.createOrder(this.cart.id)
                this.meta.actions.onAddressSelect.loadingText = 'Updating shipping information...'
                const orderDetails = await OrderService.updateShipping(address)

                await this.$router.push({ name: 'OrderDetails', query: { order_id: orderDetails.id } })
                // end of temp code.
            } catch (e) {
                this.$log.error('Error while updating address', e)
            } finally {
                this.meta.actions.onAddressSelect.loading = false
            }
        }
    }

}
</script>

<style scoped>

</style>
