// import * as Fingerprint2 from 'fingerprintjs2';
// import * as UAParser from 'ua-parser-js';
// import axios from 'axios';

// import { Http } from './http.init';
// import { ResponseWrapper, ErrorWrapper } from './util';
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';

// import { API_URL } from '../.env';

// let BEARER = '';

export class AuthService {
  // ...other methods remain the same

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const store = useStore(); // Access the store using useStore hook
    const accessTokenExpDate = store.state.auth.accessTokenExpDate - 10;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  // ...other methods remain the same
}

/**
 ******************************
 * @private_methods
 ******************************
 */

// function _parseTokenData(accessToken) {
//   let payload = '';
//   let tokenData = {};

//   try {
//     payload = accessToken.split('.')[1];
//     tokenData = JSON.parse(atob(payload));
//   } catch (error) {
//     throw new Error(error);
//   }

//   return tokenData;
// }

// function _resetAuthData() {
//   // reset userData in store
//   const store = useStore();
//   store.commit('user/SET_CURRENT_USER', {});
//   store.commit('auth/SET_ATOKEN_EXP_DATE', null);
//   // reset tokens
//   AuthService.setRefreshToken('');
//   AuthService.setBearer('');
// }

// function _setAuthData({ accessToken, exp } = {}) {
//   const store = useStore();
//   AuthService.setRefreshToken('true');
//   AuthService.setBearer(accessToken);
//   store.commit('auth/SET_ATOKEN_EXP_DATE', exp);
// }

// function _getFingerprint() {
//   return new Promise((resolve, reject) => {
//     async function getHash() {
//       const options = {
//         excludes: {
//           plugins: true,
//           localStorage: true,
//           adBlock: true,
//           screenResolution: true,
//           availableScreenResolution: true,
//           enumerateDevices: true,
//           pixelRatio: true,
//           doNotTrack: true,
//           preprocessor: (key, value) => {
//             if (key === 'userAgent') {
//               const parser = new UAParser(value);
//               // return customized user agent (without browser version)
//               return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`;
//             }
//             return value;
//           },
//         },
//       };

//       try {
//         const components = await Fingerprint2.getPromise(options);
//         const values = components.map((component) => component.value);
//         console.log('fingerprint hash components', components);

//         return String(Fingerprint2.x64hash128(values.join(''), 31));
//       } catch (e) {
//         reject(e);
//       }
//     }

//     if (window.requestIdleCallback) {
//       console.log('get fp hash @ requestIdleCallback');
//       requestIdleCallback(async () => resolve(await getHash()));
//     } else {
//       console.log('get fp hash @ setTimeout');
//       setTimeout(async () => resolve(await getHash()), 500);
//     }
//   });
// }
