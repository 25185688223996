<template>
    <div>
        <div class="mb-3">
            <h4 class="title is-size-5 has-text-grey-dark is-capitalized has-text-weight-bold">Welcome To Seller Hub</h4>
        </div>
        <div class="content">
            <p class="has-text-grey-dark is-size-7">Our Seller App is the most advanced
                communication bridge which is enabled
                in a user friendly dashboard and inquiry
                management . The application consists of
                many features that will help your business
                grow beyond the current geographical
                area.
            </p>
        </div>
        <div class="pb-4">
            <h4 class="is-size-4 has-text-weight-bold has-text-danger-dark">Register Now</h4>
        </div>
        <div class="box is-primary is-primary-addon-1 px-6">
            <form name="contactForm" id="contact_form" class="form-border" method="post" action="" @submit.prevent="onSubmit">
                <div>
                    <div class="has-text-centered">
                        <h5 class="has-text-weight-bold my-4 has-text-white">Personal Details</h5>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Business Name <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="business_name">
                                    <span v-if="errors.business_name" class="error has-text-danger">{{ errors.business_name[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Contact Name <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="contact_name">
                                    <span v-if="errors.contact_name" class="error has-text-danger">{{ errors.contact_name[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Mobile Number <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="contact_phone">
                                    <span v-if="errors.contact_phone" class="error has-text-danger">{{ errors.contact_phone[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Whatsapp Number <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="whatsapp_number">
                                    <span v-if="errors.whatsapp_number" class="error has-text-danger">{{ errors.whatsapp_number[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Email <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="email">
                                    <span v-if="errors.email" class="error has-text-danger">{{ errors.email[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="has-text-centered">
                        <h5 class="has-text-weight-bold my-4 has-text-white">Business Information</h5>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <!-- <b-field class="file is-primary">
                                <b-upload expanded v-model="files.logo.file_data" v-on:input="onFileUpload" class="file-label" >
                                    <span class="file-cta has-background-dark">
                                        <b-icon
                                            pack="fas"
                                            icon="upload">

                                        </b-icon>
                                        <span class="file-label">Upload your business logo</span>
                                    </span>
                                    <span class="file-name" v-if="files.logo.file_data">
                                        {{ files.logo.file_data.name }}
                                    </span>
                                </b-upload>
                            </b-field> -->

                            <b-field class="file">
                                <b-upload expanded v-model="files.logo.file_data" v-on:input="onFileUpload">
                                    <a class="button has-text-white is-fullwidth has-background-dark border-none">
                                    <b-icon icon="upload"></b-icon>
                                    <span v-if="files.logo.file_data">{{ files.logo.file_data.name }}</span>
                                    <span v-else>{{ "Upload your business logo" }}</span>
                                    </a>
                                </b-upload>
                            </b-field>
                            <span v-if="errors.logo" class="error has-text-danger">{{ errors.logo[0] }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="has-text-centered">
                        <h5 class="has-text-weight-bold my-4 has-text-white">Contact Information</h5>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Address<span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <textarea class="textarea" v-model="address_line_1"></textarea>
                                    <span v-if="errors.address_line_1" class="error has-text-danger">{{ errors.address_line_1[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">Emirate <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <b-select
                                        placeholder="Select a State"
                                        size="is-small"
                                        label="State"
                                        v-model="state_id"
                                        expanded>
                                        <option
                                            v-for="option in $store.state.ref_code.data.states.data"
                                            :value="option.code"
                                            :key="option.name">
                                            {{ option.name }}
                                        </option>
                                    </b-select>
                                    <span v-if="errors.state_id" class="error has-text-danger">{{ errors.state_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-weight-normal is-size-7 has-text-white">City <span class="has-text-danger">*</span></label>
                                <div class="control">
                                    <b-select
                                        placeholder="Select a City"
                                        size="is-small"
                                        label="City"
                                        v-model="city_id"
                                        expanded>
                                        <option
                                            v-for="option in $store.state.ref_code.data.cities.data"
                                            :value="option.code"
                                            :key="option.name">
                                            {{ option.name }}
                                        </option>
                                    </b-select>
                                    <span v-if="errors.city_id" class="error has-text-danger">{{ errors.city_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <GmapMap
                            :center="myCoordinates"
                            :zoom="zoom"
                            :draggable="true"
                            class="map-width"
                            style="height: 20rem; margin: 32px auto 0px auto"
                            ref="mapRef"
                            @dragend="handleDrag">
                            <GmapMarker
                                :position="mapCoordinates"
                                :clickable="false"
                                :draggable="false">
                            </GmapMarker>
                        </GmapMap>
                    </div>
                </div>
                <div class="pb-3">
                    <b-checkbox size="is-small" type="is-white" v-model="checked"><router-link to="/terms-and-conditions" target='_blank'><span class="has-text-white is-size-6">I accept all the</span> <span class="has-text-warning is-size-6">Terms and Conditions</span></router-link></b-checkbox>
                </div>
                <div class="columns pb-5">
                    <div class="column has-text-centered">
                        <button :disabled="!checked" class="button has-background-dark is-rounded border-none px-6">
                            <h5 class="is-size-5 has-text-white has-text-weight-semibold">Submit</h5>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {
    mapActions
} from 'vuex'
import {
    SellerService
} from '@/services/seller.service'
import { FileuploadService } from '@/services/fileupload.service'
export default {
    name: 'JoinAsSellerForm',
    data: function () {
        return {
            pageLoaded: false,
            errors: [],
            country: 0,
            country_id: [],
            state: 0,
            state_id: [],
            city: 0,
            city_id: [],
            contact_name: [],
            datavalue: [],
            email: [],
            contact_phone: [],
            // business_type: [],
            // core_business: [],
            // delivery_type: [],
            business_name: [],
            address_line_1: [],
            // address_line_2: [],
            // postal_code: null,
            // contact_name_2: null,
            // contact_phone_2: null,
            // bank_acc_name: null,
            // bank_acc_number: null,
            // bank_name: null,
            // swift_code: null,
            // beneficiary_name: null,
            whatsapp_number: [],
            data: null,
            response: null,
            map: null,
            zoom: 16,
            myCoordinates: {
                lat: 0,
                lng: 0
            },
            files: {
                logo: {
                    file_id: null,
                    file_data: null
                }
                // documents: {
                //     file_data: [],
                //     file_ids: []
                // }
            },
            checked: false
        }
    },
    created () {
        // does the user have a saved center? use it instead of the default
        if (localStorage.center) {
            this.myCoordinates = JSON.parse(localStorage.center)
        } else {
            // user's coordinates from browser request
            this.$getLocation({})
                .then((coordinates) => {
                    this.myCoordinates = coordinates
                })
                .catch((error) => alert(error))
        }

        // does the user have a saved zoom? use it instead of the default
        if (localStorage.zoom) {
            this.zoom = parseInt(localStorage.zoom)
        }
    },
    mounted () {
        this.refCodeRefresh([
            'countries',
            'states',
            'delivery_type',
            'business_type',
            'core_business',
            'cities'
        ]).then(() => {
            this.pageLoaded = true
        })
        // add the map to a data object
    },
    updated () {
        if (!this.map && this.$refs && this.$refs.mapRef) {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.map = map
            })
        }
    },
    computed: {
        mapCoordinates () {
            if (!this.map) {
                return {
                    lat: 0,
                    lng: 0
                }
            }

            return {
                lat: this.map.getCenter().lat(),
                lng: this.map.getCenter().lng()
            }
        }
    },
    methods: {
        ...mapActions(['refCodeRefresh']),

        onFileUpload (event, args) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(event)
                // eslint-disable-next-line no-console
                console.log(args)
            }
        },
        handleDrag () {
            // get center and zoom level, store in localstorage
            const center = {
                lat: this.map.getCenter().lat(),
                lng: this.map.getCenter().lng()
            }
            const zoom = this.map.getZoom()

            localStorage.center = JSON.stringify(center)
            localStorage.zoom = zoom
        },
        deleteDocument (index) {
            this.files.documents.file_data.splice(index, 1)
        },
        async handleFileUploads () {
            // logo

            this.files.logo.file_id = null
            if (this.files.logo.file_data !== null) {
                const response = await FileuploadService.uploadFile(this.files.logo.file_data)
                this.files.logo.file_id = response.guid
            }

            // this.files.documents.file_ids = []
            // if (this.files.documents.file_data.length > 0) {
            //     for (let i = 0; i < this.files.documents.file_data.length; i++) {
            //         const file_data = this.files.documents.file_data[i]
            //         const response1 = await FileuploadService.uploadFile(file_data)
            //         this.files.documents.file_ids.push(response1.guid)
            //     }
            // }
        },
        async onSubmit () {
            await this.handleFileUploads()
            // console.log(this.files.documents.file_ids)
            this.data = {
                business_name: this.business_name,
                contact_name: this.contact_name,
                contact_phone: this.contact_phone,
                // business_type: this.business_type,
                // core_business: this.core_business,
                // delivery_type: this.delivery_type,
                email: this.email,
                // country: this.country_id,
                state: this.state_id,
                city: this.city_id,
                address_line_1: this.address_line_1,
                // address_line_2: this.address_line_2,
                // postal_code: this.postal_code,
                // bank_acc_name: this.bank_acc_name,
                // bank_acc_number: this.bank_acc_number,
                // bank_name: this.bank_name,
                // swift_code: this.swift_code,
                // beneficiary_name: this.beneficiary_name,
                // contact_name_2: this.contact_name_2,
                // contact_phone_2: this.contact_phone_2,
                longitude: this.mapCoordinates.lng,
                latitude: this.mapCoordinates.lat,
                whatsapp_number: this.whatsapp_number,
                logo: this.files.logo.file_id
                // documents: this.files.documents.file_ids
            }
            try {
                this.response = await SellerService.saveSeller(this.data)
                this.$router.push('/confirmation')
            } catch (e) {
                this.errors = e.response.data.errors
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('this.errors', e.response.data.errors)
                }
                if (this.errors !== null) {
                    this.$buefy.notification.open({
                        duration: 5000,
                        message: 'Oops, There is some error in the data entered.',
                        position: 'is-bottom-right',
                        type: 'is-danger',
                        hasIcon: true
                    })
                }
            }
        }
    }
}
</script>
