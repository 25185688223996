import axios from 'axios'
import { API_URL } from '@/.env'

export class CartService {
    static async getCart (opts) {
        const response = await axios.get(`${API_URL}/api/customer/cart`, {
            params: {
                clear: opts?.clear || 0
            }
        })
        return response.data
    }

    /**
     * Add an enquiry response to cart.
     *
     * @param item the search response
     * @param opts
     * @returns {Promise<void>}
     */
    static async addItemToCart (item) {
        const reqData = {
            cart_id: item.cart_id,
            search_term_response_id: item.search_term_response_id,
            clear: 1
        }
        const response = await axios.post(`${API_URL}/api/customer/cart/items`, reqData)
        return response.data
    }

    static async getDefaultAddress () {
        const response = await axios.get(`${API_URL}/api/customer/cart/default-address`)
        return response.data
    }
}
