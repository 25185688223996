<template>
    <div class="page-wrapper">
        <div v-if="enquiries.loading" class="has-text-centered">
            <page-loader></page-loader>
        </div>
        <div v-else>
            <div class="page-title">
                <div class="title">
                    Enquiries
                </div>
                <div class="subtitle">
                    View all enquiries
                </div>
            </div>

            <div class="page-content">
                <div class="columns">
                    <div class="column is-two-fifths">
                        <div class="card card__all_enquiries">
                            <header class="card-header">
                                <p class="card-header-title">
                                    Enquiry Responses
                                </p>
                            </header>
                            <div class="card-content p-0">
                                <div class="content">
                                    <div class="me__enquiries_container">
                                        <div class="me__enquiry" v-for="(e, index) in enquiries.pData.data" :key="e.id" v-bind:class="{ 'is-active': e.id==selectedEnquiry.id }" v-on:click="onClickEnquiry(index)">
                                            <div class="columns is-mobile px-4 mt-1">
                                                <div class="column is-2">
                                                    <img src="/images/search-mobile.png">
                                                </div>
                                                <div class="column is-5">
                                                    <div>
                                                        <p class="is-size-6  mb-0">{{ e.term }}</p>
                                                        <p class="is-size-7 has-text-weight-bold">{{ e.business_name }}</p>
                                                    </div>
                                                </div>
                                                <div class="column is-5">
                                                    <div class="has-text-right">
                                                        <p class="is-size-6 mb-0">{{ formatAmount(e.price) }}</p>
                                                        <p class="is-size-7">{{ isoToLocale(e.created_at) }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-three-fifths">
                        <div class="card card__enquiry_detail">
                            <header class="card-header">
                                <p class="card-header-title">
                                    Details
                                </p>
                            </header>
                            <div class="card-content">
                                <div class="content">
                                    <div align="center" class="is-centered" style="border:1px solid #ccc;">
                                        <div class="p-5">
                                            <div class="columns is-mobile">
                                                <div class="column is-2">
                                                    <img src="/images/enquiry-mobile.png">
                                                </div>
                                                <div class="column is-mobile has-text-right-desktop ">
                                                    <div class="px-2">
                                                        <h3 class="is-size-7-mobile mb-0">
                                                            {{ selectedEnquiry.term }}
                                                        </h3>
                                                        <h3 class=" is-size-7-mobile has-text-weight-bold mt-0 mb-0">
                                                            {{ formatAmount(selectedEnquiry.price) }}
                                                        </h3>
                                                        <p>
                                                            {{ selectedEnquiry.business_name }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="columns is-mobile is-centered mt-5">
                                        <div class="column is-half-desktop ">
                                            <div class="has-text-centered ">
                                                <b-button type="is-primary" class="is-primary-addon-1" :loading="actions.onProceedAction.loading" v-on:click="onProceedEnquiry">Proceed</b-button>
                                            </div>
                                        </div>
                                    </div>

<!--                                    <div class="px-10" style="margin-top:18%;">-->
<!--                                        <hr class="hr-bg">-->
<!--                                        &lt;!&ndash; <h3 class="is-size-7-mobile pl-2 pr-2"> Type your message here<span class="is-pulled-right"-->
<!--                                                style="width:2.3rem"><img src="/images/enquiry-send-icon.svg"></span></h3> &ndash;&gt;-->
<!--                                        <div class="field has-addons">-->
<!--                                            <div class="control is-expanded">-->
<!--                                                <input class="input" type="text" placeholder="Type your message here">-->
<!--                                            </div>-->
<!--                                            <div class="control">-->
<!--                                                <a class="button is-info botton-color">-->
<!--                                                    <i class="fas fa-paper-plane fa-lg"></i>-->
<!--                                                </a>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SearchService } from '@/services/search.service'
import { CartService } from '@/services/cart.service'

export default {
    name: 'Enquiry',
    data: function () {
        return {
            enquiries: {
                loading: true,
                data: [],
                pagination: {}
            },
            selectedEnquiry: null,
            cart: null,
            actions: {
                onProceedAction: {
                    loading: false
                }
            }
        }
    },
    async mounted () {
        try {
            // load the cart with clear enabled
            this.cart = await CartService.getCart()
            const e = await SearchService.myEnquiries()
            this.enquiries.loading = false
            this.enquiries.pData = e.data

            if (this.enquiries.pData.data.length > 0) {
                this.selectedEnquiry = this.enquiries.pData.data[0]
            }
        } catch (e) {
            this.$log.error('Failed to load cart', e)
        }
    },
    methods: {
        onClickEnquiry (index) {
            this.selectedEnquiry = this.enquiries.pData.data[index]
        },
        async onProceedEnquiry () {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(this.selectedEnquiry)
            }

            const reqData = {
                cart_id: this.cart.id,
                search_term_response_id: this.selectedEnquiry.id
            }
            try {
                this.actions.onProceedAction.loading = true
                await CartService.addItemToCart(reqData)
                this.$router.push({ name: 'OrderDeliveryAddress' })
            } catch (e) {
                this.$log.error('Failed while adding to cart', e)
                this.actions.onProceedAction.loading = false
            }
        }
    }
}
</script>

<style scoped>

</style>
