<template>
    <div class="modal-card" style="width:auto">
        <div class="modal-card-body">
            <!-- LOGIN 1 -->
            <div class="p-3" id="view-vendor_choose" v-if="currentView == 'Home'">
                <form @submit.prevent="onRequestOTP">
                    <div class="columns is-mobile is-marginless">
                        <div class="column">
                            <div class="mt-2">
                                <h4 class="title is-size-5">Welcome</h4>
                                <h5 class="subtitle is-6 font-color">Sign into your account</h5>
                            </div>
                        </div>
                        <div class="column">
                            <div class="image is-64x64 is-pulled-right">
                                <img src="/images/user-circle.svg" alt="User Icon">
                            </div>
                        </div>
                    </div>
                    <div class="column is-mobile">
                        <div class="margin-top-2">
                            <div class="field has-addons">
                                <div class="control" style="width:25%">
                                    <span class="select is-rounded is-danger" style="font-size:1.25rem;">
                                        <select v-model="isd_code">
                                            <option v-for="option in isd_codes" :value="option.value"
                                                :key="option.value">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </span>
                                </div>
                                <div class="control">
                                    <input class="input is-danger is-rounded is-medium" type="text"
                                        placeholder="Mobile Number" v-model="mobile_number" size="40">
                                    <span v-if="errors.mobile_number" class="error">{{ errors.mobile_number[0] }}</span>
                                </div>
                            </div>
                            <div class="has-text-centered">
                                <button class="button is-primary is-medium is-fullwidth"
                                    :class="{ 'is-loading': form.onRequestOTP.loading }">
                                    Send OTP
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="column is-mobile has-text-centered">
                        <div class="margin-top-3">
                            <div class="divider-horizontal is-danger">
                                <span class="title is-5 font-color">OR</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 class="title is-5 has-text-centered">Signin Using</h1>
                    </div>
                    <div class="columns is-mobile is-centered mt-5 mb-0">
                        <div class="column is-narrow">
                            <a href="" @click.prevent="openGoogle">
                                <img src="/images/search.png" alt="Google Signin">
                            </a>
                        </div>
                        <div class="column is-narrow">
                            <a href="" @click.prevent="openFacebook">
                                <img src="/images/login-popup-facebook.png" alt="Facebook Signin">
                            </a>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div class="mt-5 mb-5">
                                <p><span class="ml-4">By continuing you agree to our</span> <br>
                                    <a href="/terms-and-conditions"><span class="has-text-danger">Terms &
                                            Conditions</span></a> and <a href="/privacy-policy"><span
                                            class="has-text-danger">Privacy Policy</span></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!-- OTP Page -->
            <!-- Similar structure for other views -->
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { LoginService } from '@/services/login.service';
import { AuthService } from '@/services/auth.service';
import * as hellojs from 'hellojs';
// import PulseLoader from 'vue-spinner/src/PulseLoader';
// import { ProfileService } from '@/services/profile.service';
// import AddAddressPopupModal from '@/components/profile/AddAddressPopupModal';
import { RefCodeService } from '@/services/ref_code.service';

const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

function logToLocalStorage(message) {
    const logs = JSON.parse(localStorage.getItem('Logs')) || [];
    logs.push({ timestamp: new Date().toISOString(), message });
    localStorage.setItem('Logs', JSON.stringify(logs));
}

export default {
    name: 'LoginPopupModal',
    props: ['latitude', 'longitude'],
    components: {},
    setup() {
        const form = ref({
            onRequestOTP: { loading: false },
            onRequestSignup: { loading: false },
            onSubmitSignup: { loading: false },
        });
        const errors = ref([]);
        const mobile_number = ref('');
        const isd_code = ref('91');
        const otpInput = ref('');
        const request_id = ref('');
        const isd_codes = ref([]);
        const name = ref('');
        const email = ref('');
        const currentView = ref('Home');
        const oauth = ref({ loader: true });

        onMounted(async () => {
            isd_codes.value = await RefCodeService.getAvailableISDCodes();
            isd_code.value = isd_codes.value[0].value;

            hellojs.init({
                google: googleClientId,
            });
        });

        const onRequestOTP = async () => {
            try {
                form.value.onRequestOTP.loading = true;
                const response = await LoginService.saveLogin({
                    mobile_number: mobile_number.value,
                    isd_code: isd_code.value,
                });
                request_id.value = response.data.request_id;
                currentView.value = 'OtpPage';
            } catch (e) {
                errors.value = e.response.data.errors;
            } finally {
                form.value.onRequestOTP.loading = false;
            }
        };

        const onRequestSignup = async () => {
            try {
                form.value.onRequestSignup.loading = true;
                const response = await LoginService.saveOtp({
                    request_id: request_id.value,
                    otp: otpInput.value,
                });
                if (!response.data.is_user) {
                    currentView.value = 'SignupPage';
                } else {
                    const token = await AuthService.getToken(request_id.value);
                    const userInfo = await AuthService.getUserInfo(token);
                    this.$store.commit('auth/AUTH_SUCCESS', userInfo);
                    this.$emit('close');
                }
            } catch (e) {
                errors.value = e.response.data.errors;
            } finally {
                form.value.onRequestSignup.loading = false;
            }
        };

        const onSubmitSignup = async () => {
            try {
                form.value.onSubmitSignup.loading = true;
                const response = await LoginService.saveCustomer({
                    name: name.value,
                    email: email.value,
                    request_id: request_id.value,
                });
                const userInfo = await AuthService.getUserInfo(response);
                this.$store.commit('auth/AUTH_SUCCESS', userInfo);
                this.$emit('close');
            } catch (e) {
                errors.value = e.response.data.errors;
            } finally {
                form.value.onSubmitSignup.loading = false;
            }
        };

        const openGoogle = () => {
            currentView.value = 'OAuthLogin';

            hellojs.login('google', {
                scope: 'email,profile',
                redirect_uri: '/oauth-redirect',
            })
                .then(async () => {
                    const authRes = hellojs('google').getAuthResponse();
                    logToLocalStorage(authRes);
                    try {
                        const response = await AuthService.attempOAuth2Login(authRes);
                        this.$store.commit('auth/AUTH_SUCCESS', response);
                        window.location.reload();
                    } catch (e) {
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.log(e);
                        }
                    } finally {
                        this.$emit('close');
                    }
                }, function (e) {

                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log(e);
                    }
                });
        };

        return {
            form,
            errors,
            mobile_number,
            isd_code,
            otpInput,
            request_id,
            isd_codes,
            name,
            email,
            currentView,
            oauth,
            onRequestOTP,
            onRequestSignup,
            onSubmitSignup,
            openGoogle,
        };
    },
};
</script>



<style scoped></style>
