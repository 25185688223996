<template>
    <div id="oauth-redirect-page">

    </div>
</template>

<script>

// import axios from 'axios'
import 'hellojs'

export default {
    name: 'OAuthRedirect',
    data () {
        return {
        }
    },
    components: {

    },
    mounted () {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Inside mounted()')
        }
    }
}

</script>
