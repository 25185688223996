<template>
    <div class="has-text-centered m-6">
      <clip-loader :loading="true" color="#999" />
      <strong>{{ loadingText }}</strong>
    </div>
  </template>
  
  <script>
  import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js';
  
  export default {
    name: 'PageLoader',
    components: {
      ClipLoader
    },
    props: {
      loadingText: {
        default: 'Loading',
        type: String
      }
    }
  }
  </script>
  
  <style scoped>
  /* Your custom styles */
  </style>
  