<template>
    <div class="modal-card" style="width:auto">
        <div class="modal-card-body">
            <div class="p-3" id="view-vendor_choose" v-if="currentView == 'Home'">
                <form v-on:submit.prevent="onRequestOTP">
                    <div class="columns is-mobile is-marginless">
                        <div class="column is-three-quarters">
                            <div class="mt-2">
                                <h4 class="title is-size-5">Validate your phone number</h4>
                                <h5 class="subtitle is-6 font-color">Looks like your phone number is missing</h5>
                            </div>
                        </div>
                        <div class="column is-one-quarter">
                            <div class="is-pulled-right">
                                <b-icon
                                    pack="fas"
                                    icon="phone-slash"
                                    size="is-large">
                                </b-icon>
                            </div>
                        </div>
                    </div>
                    <div class="column is-mobile">
                        <div class="margin-top-2">
                            <div class="field">
                                <div class="control">
                                    <input class="input is-danger is-rounded is-medium " type="text"
                                           placeholder="Mobile Number" v-model="form.onRequestOTP.data.mobile_number" size="40">
                                    <span v-if="form.onRequestOTP.errors.mobile_number" class="error">{{ form.onRequestOTP.errors.mobile_number[0] }}</span>
                                </div>
                            </div>
                            <div class="has-text-centered ">
                                <b-button tag="button" native-type="submit" type="is-primary" class="is-primary-addon-1 is-medium is-fullwidth" :loading="form.onRequestOTP.loading">
                                    Send OTP
                                </b-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="p-3" id="view-otp_page" v-if="currentView == 'OtpPage'">
                <form v-on:submit.prevent="onRequestValidate()">
                    <div class="column left ">
<!--                        <figure class="image is-24x24 ">-->
<!--                            <a href="" v-on:click.prevent.stop="onBackArrow" class="is-active" id="arrow-back1">-->
<!--                                <img src="/images/Icon ionic-ios-arrow-round-back.svg">-->
<!--                            </a>-->
<!--                        </figure>-->
                    </div>
                    <div class="p-3">
                        <div class="columns is-mobile is-centered has-text-centered">
                            <div class="column center">
                                <img style="width:3.5rem;" src="/images/pincode.svg">
                            </div>
                        </div>
                        <div>
                            <div class="margin-top-300">
                                <h1 class="title is-6 has-text-centered">Enter your One Time Password</h1>
                            </div>
                        </div>
                        <div class="columns  is-centered  mt-2">
                            <div class="column is-half">
                                <div class="otpBox ">
                                    <v-otp-input ref="otpInput" input-classes="otp-input" separator="" :num-inputs="4" :should-auto-focus="true" :is-input-num="true" v-on:on-complete="handleOnComplete" />
                                </div>
                            </div>
                        </div>
                        <div class=" has-text-centered mt-2"><span v-if="form.onRequestValidate.errors.otp" class="error">{{ form.onRequestValidate.errors.otp[0] }}</span></div>
                        <div class="columns">
                            <div class="column ">
                                <h2 class="is-pulled-right"> Didn't get Otp?</h2>
                            </div>
                        </div>
                    </div>

                    <div class="column center">
                        <div class="has-text-centered">
                            <b-button tag="button" native-type="submit" type="is-primary" class="is-centered is-primary is-primary-addon-1" :loading="form.onRequestValidate.loading">
                                Submit
                            </b-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import { LoginService } from '@/services/login.service'

export default {
    name: 'PhoneNumberModal',
    data: function () {
        return {
            currentView: 'Home',
            form: {
                onRequestOTP: {
                    loading: false,
                    data: {
                        mobile_number: null
                    },
                    errors: {

                    }
                },
                onRequestValidate: {
                    loading: false,
                    data: {
                        otp: null
                    },
                    errors: {

                    }
                }
            },
            request_id: null
        }
    },
    components: {},
    mounted () {

    },
    methods: {
        async onRequestOTP () {
            const data = {
                mobile_number: this.form.onRequestOTP.data.mobile_number
            }

            try {
                this.form.onRequestOTP.loading = true
                const response = await LoginService.saveLogin(data)
                this.request_id = response.data.request_id
                this.currentView = 'OtpPage'
            } catch (e) {
                this.form.onRequestOTP.errors = e.response.data.errors
            } finally {
                this.form.onRequestOTP.loading = false
            }
        },
        async onRequestValidate () {
            this.data = {
                request_id: this.request_id,
                otp: this.form.onRequestValidate.data.otp
            }
            try {
                this.form.onRequestValidate.loading = true
                await LoginService.valiadteNumber(this.data)
                this.$emit('close')
                window.location.reload()
            } catch (e) {
                this.form.onRequestValidate.errors = e.response.data.errors
                
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('this.errors', e.response.data.errors)
                }
            } finally {
                this.form.onRequestValidate.loading = false
                this.$refs.otpInput.clearInput()
            }
        },
        async handleOnComplete (value) {
            this.form.onRequestValidate.data.otp = value
            await this.onRequestValidate()
        }
    }
}
</script>
<style scoped>
</style>
