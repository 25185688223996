// import { createApp } from "vue";
// import AppLayout from './views/layout/index.vue';
// import router from './router';
// import store from './store'; // Import the Vuex store
// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'


// import './mixins';
// import './plugins';
// import './thirdParty';

// import '@/assets/scss/app.scss';
// // import { registerPlugins } from './plugins';
// // import { registerThirdParty } from './thirdParty';

// import { GoogleMap, Marker } from 'vue3-google-map'; // Import specific components
// import axios from 'axios';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import OTPInput from '@bachdgvn/vue-otp-input';

// // Access environment variables
// const apiUrl = process.env.VUE_APP_API_URL;
// // const googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;

// const app = createApp(AppLayout);

// // Register global components
// app.component('v-otp-input', OTPInput);
// app.component('GoogleMap', GoogleMap);
// app.component('Marker', Marker);
// app.use(Buefy);

// // Vue plugins
// app.use(store);
// app.use(router);



// // Set Axios base URL from environment variable
// axios.defaults.baseURL = apiUrl;

// // Initialize AOS animations
// app.directive('aos', {
//     mounted() {
//         AOS.init();
//     }
// });

// // Mount the app with proper store usage
// app.mixin({
//     mounted() {
//         // Access the store directly here
//         store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth);
//         window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth));

//         // Load the user details and set them in the store
//         axios.get('/sanctum/csrf-cookie').then(() => {
//             // handle the response if needed
//         }).catch(error => {
//             if (process.env.NODE_ENV === 'development') {
//                 // eslint-disable-next-line no-console
//                 console.error('CSRF cookie loading failed:', error);
//             }
//         });
//     },
//     beforeUnmount() {
//         window.removeEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth));
//     },
// });

// // Mount the app
// app.mount('#app');


import { createApp } from "vue";
import AppLayout from './views/layout/index.vue';
import router from './router';
import store from './store'; // Import the Vuex store
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import './mixins';
import './plugins';
import './thirdParty';

import '@/assets/scss/app.scss';
import { GoogleMap, Marker } from 'vue3-google-map'; // Import specific components
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OTPInput from '@bachdgvn/vue-otp-input';

const apiUrl = process.env.VUE_APP_API_URL;

const app = createApp(AppLayout);

// Register global components
app.component('v-otp-input', OTPInput);
app.component('GoogleMap', GoogleMap);
app.component('Marker', Marker);

// Use Buefy and provide it
app.use(Buefy);
app.provide('$buefy', app.config.globalProperties.$buefy);


// Vue plugins
app.use(store);
app.use(router);

// Set Axios base URL from environment variable
axios.defaults.baseURL = apiUrl;

// Initialize AOS animations
app.directive('aos', {
    mounted() {
        AOS.init();
    }
});

// Mount the app with proper store usage
app.mount('#app');
