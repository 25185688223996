<template>
    <div id="seller-directory">
        <page-loader v-if="meta.actions.page.loading === true"></page-loader>
        <div v-else>
            <div class="page-content">
                <div class="columns ">
                    <div class="column is-one-quarter">
                        <aside class="menu sidebar">
                            <div class="sidebar-section">
                                <div class="ss-title">
                                    Category
                                </div>
                                <div class="ss-content">
                                    <form>
                                        <div class="block pt-4">
                                            <b-radio v-model="filterForm.category" native-value="all">
                                                All Categories
                                            </b-radio>
                                            <b-radio v-for="category in coreBusinessList" v-model="filterForm.category" v-bind:key="category.name" :native-value="category.name">
                                                {{ category.name }}
                                            </b-radio>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="column is-three-quarters">
                        <page-loader v-if="meta.actions.loadSeller.loading === true"></page-loader>
                        <div v-else class="">
                            <div class="columns is-multiline">
                                <div class="column is-one-third" v-for="(seller,index) in sellerList" :key="index">
                                    <div class="">
                                        <div class="columns has-border-style-1 is-gapless">
                                            <div class="column is-narrow is-primary-gradient" style="min-height:175px">
                                                <span
                                                    class=" text-bottom-top is-size-6 p-1 pb-5 is-uppercase has-text-white has-text-centered">{{
                                                        seller.core_business
                                                    }}</span>
                                            </div>
                                            <div class="column">
                                                <div class="has-text-centered p-2">
                                                    <figure class="image is-inline-block is-48x48">
                                                        <img class="is-rounded" :src="imageUrl(seller.logo)">
                                                    </figure>

                                                    <h6 class="is-size-6 has-text-weight-bold">
                                                        {{ seller.business_name }}</h6>
                                                    <p class="is-size-7"><span
                                                        v-for="(keyword,index) in seller.keywords"
                                                        :key="index">#{{ keyword.name }}, </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SellerDirectoryService } from '@/services/seller_directory.service'
import { RefCodeService } from '@/services/ref_code.service'

export default {
    name: 'SellerDirectory',
    data: function () {
        return {
            sellerList: [],
            keywordList: [],
            filterForm: {
                category: 'all'
            },
            keyword: [],
            coreBusinessList: [],
            meta: {
                actions: {
                    page: {
                        loading: true
                    },
                    loadSeller: {
                        loading: true
                    }
                }
            }
        }
    },
    async mounted () {
        this.coreBusinessList = (await RefCodeService.getReferenceCodes(['core_business'])).data.core_business
        await this.getSeller()
        this.meta.actions.page.loading = false
    },
    methods: {
        async getSeller () {
            try {
                this.meta.actions.loadSeller.loading = true
                const filter = {}
                if (this.filterForm.category !== 'all') {
                    filter.core_business = this.filterForm.category
                }
                this.sellerList = await SellerDirectoryService.getSeller(filter)
                this.meta.actions.loadSeller.loading = false
            } catch (e) {
                this.$log.error('Error while fetching seller directory', e)
            }
        }
    },
    watch: {
        'filterForm.category': function () {
            this.getSeller()
        }
    }
}
</script>

<style scoped>
.seller-logo {
    height: 90px;
    width: 90px;
}

</style>
