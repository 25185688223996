<template>
  <div v-if="isVisible" class="splash-screen">
    <div class="animation-container">
      <div class="scaling-squares-spinner" :style="spinnerStyle">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
      </div>
      <p class="splash-message">{{ currentMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
      messages: ["Loading App Library...", "Setting things up...", "Almost there..."],
      currentMessage: "",
      messageIndex: 0,
    };
  },
  methods: {
    updateMessage() {
      if (this.messageIndex < this.messages.length) {
        this.currentMessage = this.messages[this.messageIndex];
        this.messageIndex++;
      } else {
        this.currentMessage = this.messages[this.messages.length - 1];
      }
    },
    hideSplashScreen() {
      this.isVisible = false;
      localStorage.setItem('hasSeenSplashScreen', 'true');
    },
  },
  mounted() {
    this.updateMessage();
    this.messageInterval = setInterval(this.updateMessage, 3000); // Change messages every 3 seconds

    // Hide splash screen after 10 seconds
    setTimeout(() => {
      this.hideSplashScreen();
    }, 10000);

    window.addEventListener("load", () => {
      setTimeout(this.hideSplashScreen, 10000);
    });
  },
  beforeUnmount() {
    clearInterval(this.messageInterval);
  },
};
</script>

<style scoped>
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /* Adjust the background color */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.animation-container {
  text-align: center;
}

.splash-message {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
}



/* Include your fulfilling-bouncing-circle-spinner CSS here */
.scaling-squares-spinner,
.scaling-squares-spinner * {
  box-sizing: border-box;
}

.scaling-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: scaling-squares-animation 1250ms;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
}

.scaling-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  margin-right: auto;
  margin-left: auto;
  border: calc(65px * 0.04 / 1.3) solid #a7d32f;
  position: absolute;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
}

.scaling-squares-spinner .square:nth-child(1) {
  animation-name: scaling-squares-spinner-animation-child-1;
}

.scaling-squares-spinner .square:nth-child(2) {
  animation-name: scaling-squares-spinner-animation-child-2;
}

.scaling-squares-spinner .square:nth-child(3) {
  animation-name: scaling-squares-spinner-animation-child-3;
}

.scaling-squares-spinner .square:nth-child(4) {
  animation-name: scaling-squares-spinner-animation-child-4;
}


@keyframes scaling-squares-animation {

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes scaling-squares-spinner-animation-child-1 {
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-2 {
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-4 {
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}
</style>
