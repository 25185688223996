// store/index.js
import { createStore } from 'vuex'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    modules,
    strict: process.env.NODE_ENV === 'development',
    plugins: [
        createPersistedState({ key: 'findhub-web-ls', paths: ['ref_code'] })
    ]
})

export default store